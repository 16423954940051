/**
 * External dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';

/**
 * Internal dependencies
 */
import Layout from '../components/layout';
import Section from '../components/section';
import SEO from '../components/seo';
import ForgottenPasswordForm from '../components/forgotten-password-form';
import { isLoggedIn } from '../lib/auth';
import InternalLink from '../components/internal-link';

const ForgottenPasswordPage = ( props ) => {
    const {
        location,
    } = props;

    return (
        <Layout
            location={location}
            noSignUpForm
        >
            <SEO
                title="Forgotten Password"
            />
            <Section narrow>
                <h1>Forgotten Password</h1>
                { isLoggedIn()
                    ? <p className="lead">You&apos;re already logged in! <InternalLink to="/account">Go to your account</InternalLink>.</p>
                    : <ForgottenPasswordForm /> }
            </Section>
        </Layout>
    );
};

ForgottenPasswordPage.propTypes = {
    location: PropTypes.objectOf( PropTypes.any ),
};

ForgottenPasswordPage.defaultProps = {
    location: {},
};

export default ForgottenPasswordPage;
